<template>
  <vh-card shadow="hover" class="item-card">
    <!-- 卡片顶部区域（图标、文案、开关） -->
    <div class="item-card-top">
      <label class="item-card-top__icon">
        <!--业务通知类型：1=预约/报名成功通知（默认）;2=开播提醒;3=回放提醒;4=已开播通知;5=自定义-->
        <img src="../images/msg1.svg" v-show="[1, 5].includes(info.biz_notice_type)" />
        <img src="../images/msg2.svg" v-show="[2, 4].includes(info.biz_notice_type)" />
        <img src="../images/msg3.svg" v-show="info.biz_notice_type == 3" />
      </label>
      <span class="item-card-top__title">{{ info.title || '' }}</span>
      <vh-switch
        v-model="cardInfo.notice_switch"
        active-text=""
        :active-value="1"
        :inactive-value="0"
        @change="switchChangeOpen"
        size="mini"
      ></vh-switch>
    </div>
    <!-- 卡片中间区域（内容区域） -->
    <div class="item-card-center">
      <div class="item-card-center__ctx">
        <span class="item-card-center__ctx-label">直播标题：</span>
        <span class="item-card-center__ctx-content">{{ title }}</span>
      </div>
      <div class="item-card-center__ctx">
        <span class="item-card-center__ctx-label">直播时间：</span>
        <span class="item-card-center__ctx-content">{{ webinarInfo.start_time }}</span>
      </div>
      <div class="item-card-center__ctx">
        <span class="item-card-center__ctx-label">备注：</span>
        <span class="item-card-center__ctx-content">{{ remark }}</span>
      </div>
    </div>
    <div class="item-card-detail">
      <div>查看详情</div>
      <i class="vh-breadcrumb__separator vh-icon-arrow-right"></i>
    </div>
    <!-- 卡片底部区域 -->
    <div class="item-card-bottom">
      <div class="item-card-bottom__default">
        <!-- 发送状态（未发送 or 已设置多个时间点/发送时间 等） -->
        <p class="item-card_start">
          <span v-show="baseStartText" class="item-card_start_timer">
            {{ baseStartText }}
          </span>
          <!-- 1已发送 0未发送（默认） 2发送中 3已过时-->
          <span class="item-card_start_status" v-if="info.notice_switch === 0">
            <img src="../images/fill-wait.svg" />
            未开启
          </span>
          <template v-else-if="info.biz_notice_type !== 1">
            <span class="item-card_start_status" v-if="!info.task_list">
              <img src="../images/fill-wait.svg" />
              未设置
            </span>
            <span class="item-card_start_status" v-else-if="info.send_status === 2">
              <!--发送状态：0=未发送; 1=已发送; 2=发送中，3已过时-->
              <img src="../images/fill-success.svg" />
              已发送
            </span>
            <span class="item-card_start_status" v-else-if="info.send_status === 1">
              <img src="../images/fill-send.svg" />
              发送中
            </span>
            <span class="item-card_start_status" v-else>
              <img src="../images/fill-wait.svg" />
              等待发送
            </span>
          </template>
        </p>
      </div>
      <div class="item-card-bottom__hover">
        <div>
          <vh-button type="primary" round size="mini" @click="openSetDialog">发送设置</vh-button>
          <vh-button
            round
            type="default"
            ghost
            size="mini"
            @click="openNoticeDialog"
            class="send-record"
          >
            发送记录
          </vh-button>
        </div>
      </div>
    </div>
    <!-- 发送设置 -->
    <CreateMsg
      v-if="setVisible"
      :visible="setVisible"
      :tmpInfo="{
        type: 'edit',
        webinarId: info.source_id,
        activeStartTime: info.activeStartTime,
        info
      }"
      :webinarInfo="webinarInfo"
      @close="handleSetClose"
      @saveChange="saveChange"
    />
    <!-- 发送记录 -->
    <SendNoticeList
      v-if="noticeVisible"
      :tmpInfo="{
        type: 'edit',
        webinarId: info.source_id,
        info
      }"
      :biz_notice_type="info.biz_notice_type"
      :visible="noticeVisible"
      @close="handleNoticeClose"
    ></SendNoticeList>

    <vh-dialog
      custom-class="dialog-switch-tips"
      :visible.sync="switchTipsVisible"
      width="528px"
      title="提示"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="switchTipsVisible"
    >
      <div class="switch-tips-box">
        <p class="tip-item">开启失败！</p>
        <template>
          <!--3、如果未配置，弹窗提示失败，反馈失败原因（失败原因需要看微信接口是否有返回明确的信息，如果未反馈具体失败原因，仅提示失败，告知用户如何自查）-->
          <p class="tip-item">请在您的公众号中开通「订阅通知」，并将以下模版添加至「我的模版」：</p>
          <p class="tip-item">-订阅直播即将开始提醒</p>
        </template>
        <a
          class="tip-item open-docs"
          href="https://saas-doc.vhall.com/opendocs/show/1548"
          target="_blank"
        >
          点击查看操作手册
          <i class="vh-breadcrumb__separator vh-icon-arrow-right"></i>
        </a>
      </div>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button
          type="primary"
          size="medium"
          v-preventReClick
          @click="switchTipsVisible = false"
          round
        >
          我知道了
        </vh-button>
      </span>
    </vh-dialog>
  </vh-card>
</template>
<script>
  import SendNoticeList from './send-notice-list.vue';
  import CreateMsg from './create-msg.vue';
  export default {
    name: 'ItemCard',
    data() {
      return {
        switchTipsVisible: false,
        setVisible: false, // 发送设置
        noticeVisible: false, // 发送记录
        cardInfo: null
      };
    },
    props: {
      // 卡片单个信息
      info: {
        type: Object,
        default: function () {
          return {};
        }
      },
      webinarInfo: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    provide: function () {
      return {
        app: this
      };
    },
    computed: {
      baseStartText() {
        let desc = '';
        const sendTimerList = this.info.task_list || [];
        if (sendTimerList.length > 1) {
          desc = '已设置多个时间点';
        } else if (sendTimerList.length === 1) {
          desc =
            sendTimerList[0].send_time_type == 0
              ? `直播前${this.covertTimerText(sendTimerList[0].send_time)}发送`
              : sendTimerList[0].custom_send_time;
        }
        switch (this.info.biz_notice_type) {
          case 1:
            desc = '预约/报名后发送';
            break;
          // case 3:
          //   if (this.info.first_playback_switch) {
          //     if (!sendTimerList.length) {
          //       desc = "设置默认回放后发送";
          //     } else {
          //       desc = "已设置多个时间点";
          //     }
          //   }
          //   break;
        }
        return desc;
      },
      remark() {
        switch (this.info.biz_notice_type) {
          case 1:
            return '您已成功预约直播，请准时参加';
          default:
            return '订阅的直播即将开始，点击立即进入>';
        }
      },
      title() {
        let title = this.webinarInfo?.subject;
        if (title?.length > 20) {
          title = title.substring(0, 17) + '...';
        }
        return title;
      }
    },
    components: {
      SendNoticeList,
      CreateMsg
    },
    created() {
      this.cardInfo = this.info;
    },
    methods: {
      // 转换时间文案
      covertTimerText(timers) {
        if (timers == 600) {
          return '10分钟';
        } else if (timers == 1800) {
          return '30分钟';
        } else if (timers == 3600) {
          return '1小时';
        } else if (timers == 7200) {
          return '2小时';
        } else if (timers == 86400) {
          return '1天';
        } else {
          return '';
        }
      },
      handleSetClose() {
        this.setVisible = false;
      },
      handleNoticeClose() {
        this.noticeVisible = false;
      },
      openSetDialog() {
        this.setVisible = true;
      },
      openNoticeDialog() {
        this.noticeVisible = true;
      },
      switchChangeOpen(value) {
        // 存储数据
        const text = value ? '开启' : '关闭';
        let opt = {
          id: this.info?.id,
          notice_switch: value // 1=开，0=关（默认）
        };
        this.$fetch('noticeTaskTplSwitch', opt, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            if (res.code === 200) {
              this.cardInfo.notice_switch = value;
              this.$vhMessage({
                message: `${text}成功`,
                showClose: true, // 是否展示关闭按钮
                type: 'success', //  提示类型
                customClass: 'zdy-info-box' // 样式处理
              });
              this.$emit('changeSwitch', value);
            } else {
              this.cardInfo.notice_switch = !value;
              this.switchTipsVisible = true;
            }
          })
          .catch(err => {
            this.cardInfo.notice_switch = !value;
            if (err.code == 8020017) {
              this.switchTipsVisible = true;
              return;
            }
            this.$vhMessage({
              message: err.msg || `${text}失败`,
              showClose: true, // 是否展示关闭按钮
              type: 'error', //  提示类型
              customClass: 'zdy-info-box' // 样式处理
            });
            console.error(err);
          });
      },
      // 发送设置保存成功，更新卡片信息
      saveChange() {
        this.$emit('saveChange');
      }
    }
  };
</script>
<style lang="less" scoped>
  .item-card {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    .item-card-top {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
      &__icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
      }
      &__title {
        margin-left: 8px;
        margin-right: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-card-center {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.65);
      padding-bottom: 16px;
      height: 76px;
      white-space: break-spaces;
      &__ctx {
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        .item-card-center__ctx-label {
          width: 65px;
          display: inline-block;
        }
        //.item-card-center__ctx-content {
        //  display: inline-block;
        //}
      }

      .item-card_subTitle {
        margin-bottom: 10px;
      }
      .item-card_ct_item {
        margin-bottom: 10px;
      }
    }
    .item-card-detail {
      color: rgba(0, 0, 0, 0.85);
      text-align: justify;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .vh-icon-arrow-right {
        margin-right: 0;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .item-card-bottom {
      height: 34px;
      /deep/.vh-card__body {
        padding: 20px;
      }
      .item-card-bottom__default {
        border-top: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        padding-top: 14px;
        .item-card_start_timer {
          font-size: 12px;
          margin-right: 8px;
        }
        .item-card_start_desc {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.65);
        }
        .item-card_start_status {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.85);
          img {
            display: inline-block;
            width: 14px;
            height: 14px;
            vertical-align: middle;
            margin-right: 4px;
            margin-top: -2px;
          }
        }
      }
      .item-card-bottom__hover {
        border-top: 0;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        display: none;
        padding-top: 7px;
        .send-record {
          margin-left: 8px;
        }
        /deep/i {
          font-size: 20px;
          color: #595959;
        }
      }
      .color-blue {
        color: #1e4edc;
        cursor: pointer;
      }
      .color-blue__r10 {
        margin-right: 10px;
      }
      .margin-l10 {
        margin-right: auto;
      }
    }
    &:hover {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
      .item-card-center {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: justify;
        color: rgba(0, 0, 0, 0.85);
      }
      .item-card-bottom {
        height: 34px;
        .item-card-bottom__default {
          display: none;
        }
        .item-card-bottom__hover {
          display: flex;
        }
      }
    }
  }
  .dialog-switch-tips {
    .switch-tips-box {
      .tip-item {
        color: #262626;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        &.open-docs {
          margin-top: 8px;
          cursor: pointer;
          color: #0a7ff5;
          .vh-icon-arrow-right {
            color: #0a7ff5;
            font-size: 12px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
</style>
