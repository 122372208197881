import { render, staticRenderFns } from "./create-msg.vue?vue&type=template&id=2105aa07&scoped=true&"
import script from "./create-msg.vue?vue&type=script&lang=js&"
export * from "./create-msg.vue?vue&type=script&lang=js&"
import style0 from "./create-msg.vue?vue&type=style&index=0&id=2105aa07&prod&lang=less&"
import style1 from "./create-msg.vue?vue&type=style&index=1&id=2105aa07&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2105aa07",
  null
  
)

export default component.exports