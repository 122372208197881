<template>
  <vh-dialog
    :visible="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    width="940px"
    class="send-notice-list__dialog"
  >
    <div
      v-show="loading"
      class="loading_table"
      v-loading="loading"
      element-loading-background="rgba(255,255,255,.9)"
    ></div>
    <div slot="title" class="dialog__title">
      <span class="vh-dialog__title send-list-title">发送记录</span>
    </div>
    <div class="desc">
      订阅数
      <span class="num">{{ statistics.subscribe_number }}</span>
      发送成功
      <span class="num">{{ statistics.send_success_number }}</span>
      点击数
      <span class="num">{{ statistics.click_number }}</span>
    </div>
    <!-- 搜索层 -->
    <div class="search-data">
      <vh-date-picker
        v-model="searchDate"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator=" 至 "
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="searchInit"
        class="search-data__date"
        size="medium"
      />
      <vh-input
        type="text"
        class="search-data__input"
        size="medium"
        round
        placeholder="请输入昵称/手机号/openid"
        v-model.trim="query.search_keyword"
        clearable
        @clear="searchInit"
        @keyup.enter.native="searchInit"
      >
        <i slot="prefix" class="vh-input__icon vh-icon-search" @click="searchInit"></i>
      </vh-input>
      <vh-button
        round
        ghost
        size="medium"
        class="search-data__btn"
        @click="exportSendData"
        :disabled="!noticeResults.is_export"
      >
        导出数据
      </vh-button>
    </div>

    <!-- tab切换层 -->
    <vh-tabs class="send-his" v-model="query.search_data_type" @tab-click="handleClick">
      <vh-tab-pane
        :label="item.label"
        :name="item.key"
        v-for="(item, index) in tabList"
        :key="index"
      >
        <div class="tab-content">
          <vh-table ref="noticeTable" :data="noticeResults.list">
            <vh-table-column
              align="left"
              v-for="(item, index) in tableColumn"
              :key="index"
              :width="item.width"
              :label="item.label"
              :show-overflow-tooltip="!item.customTooltip"
            >
              <template slot-scope="scope">
                <div class="icon-status" v-if="item.key === 'send_status'">
                  <i
                    class="icon-dot"
                    :style="{
                      background: scope.row[item.key] == '1' ? '#0FBA5A' : '#FB2626'
                    }"
                  ></i>
                  <!-- 发送状态 1发送成功 2发送失败	 -->
                  <span>{{ scope.row.sendStatusName }}</span>
                </div>
                <!-- 没有key时（发送成功少一列）作为占位符时内容撑满	 -->
                <span v-else-if="!item.key"></span>
                <span v-else>{{ scope.row[item.key] || '--' }}</span>
              </template>
            </vh-table-column>
            <div slot="empty"></div>
          </vh-table>
          <noData
            :nullType="'nullData'"
            :height="32"
            v-if="!noticeResults.total && !loading"
            :text="emptyText"
          ></noData>
        </div>
        <div class="tab-content_page">
          <SPagination
            :total="noticeResults.total"
            :page-size="query.page_size"
            :currentPage="pageNum"
            :pageSizes="[100, 200, 300, 400]"
            @current-change="currentChangeHandler"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
      </vh-tab-pane>
    </vh-tabs>
  </vh-dialog>
</template>
<script>
  import noData from './nullPage';
  export default {
    components: {
      noData
    },
    data() {
      return {
        dialogVisible: true,
        query: {
          end_date_time: '',
          page_no: 1,
          page_size: 10,
          search_data_type: '0', // 数据类型 1发送成功 2发送失败
          search_keyword: '', // 昵称/手机号/openid
          source_id: this.app.info?.source_id,
          source_type: 1, // 业务数据id，如活动id
          start_date_time: '',
          biz_notice_type: this.biz_notice_type //this.app.info?.biz_notice_type, // 业务通知类型：1=预约/报名成功通知;2=开播提醒;
        },
        statistics: {
          subscribe_number: 0, // 订阅数
          send_success_number: 0, // 发送成功数
          click_number: 0 // 点击数
        },
        searchDate: '',
        pageNum: 1,
        vm: null,
        pickerOptions: {
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return this.dealDisabledDate(time);
          }
        },
        tabList: [
          {
            label: '全部',
            key: '0'
          },
          {
            label: '发送成功',
            key: '1'
          },
          {
            label: '发送失败',
            key: '2'
          }
        ],
        isHandle: false,
        noticeResults: {
          total: 0,
          is_export: 0,
          success_num: 0,
          fail_num: 0,
          list: []
        },
        emptyText: '暂未搜索到您想要的内容',
        loading: true
      };
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      biz_notice_type: {
        type: Number,
        default: 1
      }
    },
    inject: ['app'],
    computed: {
      tableColumn: function () {
        // 切换tab时只展示对应的列
        let widthList = ['162', '120', '120', '120', '170', '80', '80'];
        let keys = [
          'nick_name',
          'phone',
          'openid',
          'send_status',
          'send_date_time',
          'is_click',
          'remark'
        ];
        return this.setTableColumn(keys, widthList);
      }
    },
    methods: {
      searchInit() {
        this.pageNum = 1;
        this.searchSendNoticeList();
      },
      // 切换tab时构造table每个列的项以及对应宽度
      setTableColumn(keys, widthList) {
        const tableColumn = {
          nick_name: '昵称',
          phone: '手机号',
          openid: 'open_ID',
          send_status: '发送状态',
          send_date_time: '发送时间',
          is_click: '是否点击',
          remark: '备注'
        };
        let newArray = [];
        const len = keys.length - 1;
        for (let i = 0; i < len; i++) {
          const key = keys[i];
          newArray.push({
            label: tableColumn[key],
            width: `${widthList[i]}px`,
            key
          });
        }
        return [
          ...newArray,
          {
            label: tableColumn[keys[len]],
            key: keys[len]
          }
        ];
      },
      dealDisabledDate(time) {
        const day = 365 * 24 * 3600 * 1000;
        // 可以选择当天，及近一年的数据
        return time.getTime() > Date.now() || time.getTime() < Date.now() - day; //设置选择今天以及今天以前的日期
      },
      // 每页改变条数事件
      handleSizeChange(val) {
        this.pageNum = 1;
        this.query.page_size = val;
        this.searchSendNoticeList();
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.pageNum = current;
        this.searchSendNoticeList();
      },
      handleClick(tab, event) {
        this.pageNum = 1;
        this.searchSendNoticeList('init');
      },
      // 关闭弹出框
      handleClose() {
        this.dialogVisible = true;
        this.$emit('close');
      },
      // 搜索(重置第1页 或者 不重置)
      searchSendNoticeList(init) {
        const { source_id, biz_notice_type } = this.app.info;
        this.loading = true;
        if (init) {
          this.emptyText = '暂无发送数据';
        } else {
          this.emptyText = '暂未搜索到您想要的内容';
        }
        if (this.searchDate) {
          this.query.start_date_time = dayjs(this.searchDate[0]).format('YYYY-MM-DD 00:00:00'); // 有效期 - 开始时间
          this.query.end_date_time = dayjs(this.searchDate[1]).format('YYYY-MM-DD 23:59:59');
        } else {
          this.query.start_date_time = '';
          this.query.end_date_time = '';
        }

        this.query.page_no = this.pageNum;
        let params = {
          ...this.query,
          biz_notice_type
        };
        // 兼容后端逻辑，0(全部数据)不需要search_data_type
        params.search_data_type = Number(this.query.search_data_type);
        if (params.search_data_type === 0) {
          delete params.search_data_type;
        }
        if (!params.start_date_time) {
          delete params.start_date_time;
        }
        if (!params.end_date_time) {
          delete params.end_date_time;
        }

        // end_date_time 结束时间yyyy-MM-dd HH:mm:ss
        // page_no	 当前页码
        // page_size	 每页大小
        // search_data_type	数据类型 1发送成功 2发送失败
        // search_keyword 昵称/手机号/openid
        // source_id	integer 业务数据id，如活动id
        // source_type	 业务数据类型：1=活动ID;
        // start_date_time 开始时间yyyy-MM-dd HH:mm:ss
        // biz_notice_type	integer 业务通知类型：1=预约/报名成功通知;2=开播提醒;
        this.$fetch('wechatNoticeSendRecordList', params, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            this.loading = false;

            if (res && res.code == 200 && res.data) {
              res.data.list.map(item => {
                switch (item.send_status) {
                  case 1:
                    item.sendStatusName = '发送成功';
                    break;
                  case 2:
                    item.sendStatusName = '发送失败';
                    break;
                }
                switch (item.is_click) {
                  case 0:
                    item.is_click = '未点击';
                    break;
                  case 1:
                    item.is_click = '已点击';
                    break;
                }
              });
              this.noticeResults = { ...res.data, is_export: 1 };
            } else {
              this.noticeResults = {
                total: 0,
                is_export: 0,
                success_num: 0,
                fail_num: 0,
                list: []
              };
            }
          })
          .catch(e => {
            this.loading = false;
            console.log(e);
            this.noticeResults = {
              total: 0,
              is_export: 0,
              success_num: 0,
              fail_num: 0,
              list: []
            };
          });
      },
      //文案提示问题
      messageInfo(title, type) {
        if (this.vm) {
          this.vm.close();
        }
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: title,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      // 导出发送记录数据
      exportSendData() {
        let params = { ...this.query };
        delete params.search_data_type;
        if (!params.start_date_time) {
          delete params.start_date_time;
        }
        if (!params.end_date_time) {
          delete params.end_date_time;
        }
        this.$fetch('wechatNoticeSendRecordExport', params, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            this.messageInfo(
              `导出申请成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              'success'
            );
            this.$EventBus.$emit('saas_vs_download_change');
          })
          .catch(res => {
            console.log(res);
            this.messageInfo(res.msg || `导出失败`, 'error');
          });
      },
      // 获取发送记录统计数据
      getNoticeRecordStatistics() {
        const { source_id, biz_notice_type } = this.app.info;
        let opt = {
          source_id, // 业务数据id，如活动id
          source_type: 1, // 业务数据类型：1=活动ID
          biz_notice_type // 业务通知类型：1=预约/报名成功通知;2=开播提醒;
        };
        this.$fetch('wechatNoticeSendRecordStatistics', opt, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            if (res && res.code == 200 && res.data) {
              this.statistics = res.data;
            }
          })
          .catch(e => {
            console.log(e);
          });
      },
      initPage() {
        this.getNoticeRecordStatistics();
        // 初始化设置日期为最近一周
        const end = new Date();
        const start = new Date();
        end.setTime(end.getTime());
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
        this.searchDate = [dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD')];
        this.query.start_date_time = dayjs(this.searchDate[0]).format('YYYY-MM-DD 00:00:00');
        this.query.end_date_time = dayjs(this.searchDate[1]).format('YYYY-MM-DD 00:00:00');
        this.searchSendNoticeList('init');
      }
    },
    created() {
      this.dialogVisible = this.visible;
      this.initPage();
    }
  };
</script>
<style lang="less" scoped>
  .send-notice-list__dialog {
    .desc {
      margin-bottom: 28px;
      color: #1a1a1a;
      .num {
        color: #fb3a32;
        font-size: 22px;
      }
    }
    .send-list-title {
      margin-right: 4px;
    }
    .send-his {
      margin: 0 -20px;
    }
    ::v-deep .vh-dialog__body {
      height: 466px;
      overflow-y: scroll;
    }
    .loading_table {
      top: 50%;
    }
    //.saasicon_help_m {
    //  color: #8c8c8c;
    //}
  }

  .search-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .search-data__date {
      width: 276px;
      border-radius: 100px;
    }
    .search-data__input {
      margin-left: auto;
      width: 240px;
      height: 36px;
      line-height: 36px;
      /deep/.vh-input__inner {
        border-radius: 100px;
      }
    }
    .search-data__btn {
      margin-left: 12px;
    }
  }

  .tab-content {
    .icon-status {
      height: 20px;
      display: flex;
      align-items: center;
    }
    .icon-status span {
      line-height: 20px;
    }
    .icon-dot {
      width: 6px;
      height: 6px;
      display: block;
      margin-right: 8px;
      border-radius: 50%;
    }
  }
  //.resend {
  //  margin-bottom: 20px;
  //  .resend-btn {
  //    margin-right: 10px;
  //  }
  //}
  .tab-content_page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    margin-bottom: -2px;
  }
  //.color-red {
  //  color: #fb2626;
  //}
  //.color-blue {
  //  color: #1e4edc;
  //}
  //.span-between {
  //  display: inline-block;
  //  width: 12px;
  //}
</style>
