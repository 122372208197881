<template>
  <div class="msg-notification-page wechat-notification-box" id="wechatNotification">
    <div class="msg-notification-box">
      <div class="msg-notification__body" v-loading="isLoading">
        <div class="title-layout">
          <span class="base_title">
            公众号通知
            <vh-tooltip effect="dark" :visible-arrow="false" placement="right">
              <div slot="content">
                <p>
                  1.通过微信的服务通知或所绑定的公众号，对用户发送通知消息；仅在手机端微信支持通知发送；
                </p>
                <p>
                  2.默认使用「活动提醒小助手」作为发送主体，若使用您自己的公众号，请在「运营设置-公众号授权」进行配置；
                </p>
                <p>3.当您将公众号托管给微吼后，请慎重进行解绑，会影响到观众收到开播提醒的消息；</p>
                <p>4.因微信接口限制，不支持批量同时推送多个观众，可能存在接收通知延迟现象；</p>
                <p>5.如果关闭微信授权，将无法支持微信通知能力。</p>
                <p>
                  6.如果当前活动关联到专题下，且专题开启了统一鉴权，则设置的公众号通知将不会生效
                </p>
              </div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
        </div>
        <div class="msg-notification__top">
          <div class="msg-sign__top">
            <span class="msg-sign__top-label">发送主体：</span>
            <span>
              {{ authData?.wechat_oa_name ? authData.wechat_oa_name : '活动提醒小助手' }}
            </span>
            <span class="go-auth" @click="goWechatAuth">
              <template v-if="authData?.wechat_oa_appid">更换发送主体</template>
              <template v-else>
                已有公众号？前往授权
                <i class="vh-breadcrumb__separator vh-icon-arrow-right"></i>
              </template>
            </span>
          </div>
          <div class="show_tips" @click="showTips">查看说明</div>
        </div>
        <vh-row :gutter="24" class="base_row list-content" v-if="msgInfo">
          <!-- xs	<768px	超小屏 如：手机
          sm	≥768px	小屏幕 如：平板
          md	≥992px	中等屏幕 如：桌面显示器
          lg	≥1200px	大屏幕 如：大桌面显示器
          xl	≥1920px	2k屏等 -->
          <template v-for="(item, index) in msgInfo.list">
            <vh-col
              class="liveItem"
              :xs="8"
              :sm="8"
              :md="8"
              :lg="8"
              :xl="8"
              v-if="item"
              :key="`base-item-${index}`"
            >
              <ItemCard
                :info="item"
                :webinarInfo="webinarInfo"
                @changeSwitch="reloadAjax"
                @saveChange="reloadAjax"
              ></ItemCard>
            </vh-col>
          </template>
        </vh-row>

        <SPagination
          @current-change="handleCurrentChange"
          :current-page="listParams.page_no"
          :page-size="listParams.page_size"
          :total="listTotal"
          @size-change="sizeChange"
          :pageSizes="[12, 24, 36, 48, 60]"
        ></SPagination>
      </div>
    </div>

    <vh-dialog
      v-if="tipsDialog"
      custom-class="dialog-wechat-notification"
      :visible.sync="tipsDialog"
      width="560px"
      title="公众号订阅通知说明"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content-box" style="height: 446px; overflow: auto">
        <p class="content-item">
          订阅通知是一个用户主动订阅、服务号按需下发的通知能力，
          <a
            class="show-auth-instructions"
            target="_blank"
            href="https://developers.weixin.qq.com/doc/offiaccount/Subscription_Messages/intro.html"
          >
            具体介绍点此查看
          </a>
          。
        </p>
        <p class="content-item">
          已关注公众号的用户，到达发送时间，系统将通过微信公众号向用户发送消息，用户可直达H5直播观看页。
        </p>
        <p class="content-item">
          未关注公众号的用户，将通过微信服务号发送订阅消息，用户可直达H5直播观看页。
        </p>
        <p class="content-item">
          未授权公众号时，将引导关注「活动提醒小助手」公众号，通过微信服务号发送订阅消息。若您的活动传播核心场景为微信或有微信公众号粉丝沉淀需求，建议您在‘直播运营中心
          - 运营设置 - 公众号设置’中绑定自己的微信公众号，并打开此设置项实现私域粉丝沉淀。
          <span class="show-auth-instructions" @click="showAuthInstructions">查看授权说明？</span>
        </p>
        <div class="img-box">
          <img class="img-item" src="./images/notify-1.png" alt="" />
          <img class="img-item" src="./images/notify-2.png" alt="" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <vh-button type="primary" size="medium" v-preventReClick @click="tipsDialog = false" round>
          我知道了
        </vh-button>
      </span>
    </vh-dialog>

    <vh-dialog
      custom-class="dialog-auth-instructions"
      :visible.sync="authInstructionsVisible"
      width="700px"
      title="公众号授权说明"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="authInstructionsVisible"
    >
      <AuthInstructions></AuthInstructions>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button
          type="primary"
          size="medium"
          v-preventReClick
          @click="authInstructionsVisible = false"
          round
        >
          我知道了
        </vh-button>
      </span>
    </vh-dialog>
  </div>
</template>

<script>
  import { sessionOrLocal } from '@/utils/utils';
  import ItemCard from './components/item-card.vue';
  import AuthInstructions from '@/components/OfficialAcc/AuthInstructions';

  export default {
    name: 'WechatNotification',
    props: {
      webinarId: {
        type: [String, Number],
        default: '' // 活动id
      }
    },
    data() {
      return {
        listParams: {
          page_no: 1,
          page_size: 12,
          source_id: Number(this.webinarId), //配置关联实体Id
          source_type: 1, // 业务数据类型：1=活动ID;
          channel_type: 2 // 通知渠道类型：1=短信（默认）;2=公众号通知
        },
        authData: {},
        tipsDialog: false,
        authInstructionsVisible: false,
        isLoading: false,
        listTotal: 0, // 短信模版列表总数
        msgInfo: null,
        webinarInfo: {},

        vm: null,
        userPermission: null
      };
    },
    provide: function () {
      return {
        noticeApp: this
      };
    },
    components: {
      AuthInstructions,
      ItemCard
    },
    methods: {
      showTips() {
        this.tipsDialog = true;
      },
      /**
       * 展示授权说明弹框
       */
      showAuthInstructions() {
        this.authInstructionsVisible = true;
      },
      /**
       * 前往：运营设置 > 公众号授权
       */
      goWechatAuth() {
        const { href } = this.$router.resolve({
          path: '/setting/officialAccAuth'
        });
        window.open(href, '_blank');
      },
      getAuthData() {
        this.loading = true;
        this.$fetch(
          'getBoundManagedWechatOa',
          {},
          {
            'Content-Type': 'application/json'
          }
        )
          .then(res => {
            this.loading = false;
            this.authData = res?.data || {};
          })
          .catch(res => {
            this.loading = false;
            // 根据错误码判断公众号是否已授权
            this.authData = {};
          });
      },
      // 刷新界面数据
      reloadAjax() {
        this.getWechatNoticeList();
      },
      //文案提示问题
      messageInfo(title, type) {
        if (this.vm) {
          this.vm.close();
        }
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: title,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      // 翻页
      handleCurrentChange(n) {
        this.listParams.page_no = n;
        this.getWechatNoticeList();
      },

      sizeChange(current) {
        this.listParams.page_no = 1;
        this.listParams.page_size = current;
        this.getWechatNoticeList();
      },

      // 获取公众号通知列表
      getWechatNoticeList() {
        this.loading = true;
        this.$fetch('getWechatNoticeList', this.listParams, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            this.isLoading = false;
            if (res.code === 200) {
              this.msgInfo = res.data || {};
              this.listTotal = res.data.total;
            } else {
              this.messageInfo(res.msg || '接口调用失败', 'error');
            }
          })
          .catch(res => {
            this.isLoading = false;
            this.messageInfo(res.msg || '获取信息失败', 'error');
            this.listTotal = 0;
            this.msgInfo = { config_info: {}, list: [] };
          });
      },
      // 获取权限
      getConfigListIsOpen(scene = 1, webinar_id = '') {
        let opt = {
          webinar_id: webinar_id,
          webinar_user_id: JSON.parse(sessionOrLocal.get('userId')),
          scene_id: scene
        };
        this.loading = true;
        return this.$fetch('planFunctionGet', opt)
          .then(res => {
            this.loading = false;
            if (res && res.code === 200 && res.data.permissions) {
              this.WEBINAR_PES = JSON.parse(res.data.permissions);
            }
          })
          .catch(e => {
            this.loading = false;
            console.error(e);
          });
      },
      getWebinarInfo() {
        this.isLoading = true;
        this.$fetch('getWebinarInfo', { webinar_id: this.webinarId })
          .then(res => {
            this.isLoading = false;
            this.webinarInfo = res?.data || {};
            if (res.code != 200) {
              return this.$vhMessage.warning(res.msg);
            }
          })
          .catch(res => {
            this.isLoading = false;
            this.$vhMessage({
              message: res.msg || '获取信息失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.error(res);
          });
      }
    },
    created() {
      this.getAuthData();
      this.getConfigListIsOpen(1, this.webinarId);
      this.getWechatNoticeList();
      this.getWebinarInfo();
      this.userPermission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      if (this.$route.query.type === 'wechat') {
        // 锚点定位
        document.getElementById('wechatNotification').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
    },
    mounted() {
      if (this.$route.query.type == 'wechat') {
        const anchorElement = document.getElementById('wechatNotification');
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
</script>

<style lang="less" scoped>
  .saasicon_help_m {
    color: #999;
    &:hover {
      color: #666;
    }
  }
  .msg-notification-page {
    ::v-deep.vh-input {
      .vh-input__inner,
      .vh-input__count {
        font-size: 14px;
      }
    }
    .show_tips {
      margin-left: auto;
      text-align: right;
      color: #3562fa;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
    }
    ::v-deep.vh-switch__label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      &.is-active {
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .msg-notification__body {
      background: #ffffff;
      min-height: 549px;
      padding: 12px 24px;
      border-radius: 4px;
    }
    .msg-notification__top {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 14px 24px;
      background: #f5f5f5;
      border-radius: 4px;
      line-height: 28px;
      margin-bottom: 16px;

      .msg-created {
        margin-right: 20px;
      }
      .switchBox {
        margin-left: auto;
      }
      .msg-edit-icon {
        &:hover {
          color: #fb2626;
        }
      }
      .msg-sign__top {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        font-size: 14px;
        .msg-sign__top-label {
          width: 70px;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .go-auth {
          color: #3562fa;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-left: 12px;
          cursor: pointer;
          .vh-icon-arrow-right {
            color: #3562fa;
            font-size: 12px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .list-content {
      min-height: 460px;
      .liveItem {
        margin-bottom: 20px;
      }
    }
    .pagination-bottom {
      padding-top: 20px;
      display: flex;
      justify-content: center;
    }
    .msg-notification-box {
      .title-layout {
        //margin-top: 24px;
        margin-bottom: 16px;
        &::before {
          content: '';
          width: 4px;
          height: 18px;
          display: inline-block;
          margin-right: 8px;
          background: #fb3a32;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          font-style: normal;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
        .base_title {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
  .dialog-wechat-notification {
    .content-box {
      .content-item {
        text-indent: 2em;
        color: #262626;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        .show-auth-instructions {
          cursor: pointer;
          color: #0a7ff5;
        }
      }
      .img-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        .img-item {
          width: 240px;
        }
      }
    }
  }
</style>
