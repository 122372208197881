<template>
  <vh-dialog
    title="站点链接"
    width="600px"
    style="overflow: auto"
    append-to-body
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancelSelect"
    custom-class="choose-gift"
  >
    <div class="select-matrial-wrap">
      <div class="material-box" v-show="total">
        <vh-scrollbar style="height: 100%">
          <div
            v-for="item in activeList"
            :key="item.id"
            @click="doSelect(item)"
            :class="['vh-chose-active-item', item.checked ? 'checkedActive' : '']"
          >
            <label class="img-tangle" v-show="item.checked">
              <img :src="require('../images/icon-choose.png')" alt="" />
            </label>
            <div class="vh-chose-active-item__cover">
              <img class="img_box_bg" :src="item.cover" alt="" />
              <div class="vh-chose-active-item__cover-status">
                <span class="liveTag">
                  {{ 'ID:' + item.id }}
                </span>
              </div>
            </div>
            <div class="vh-chose-active-item__title">
              {{ item.name_cn || item.name_en }}
            </div>
            <div class="vh-chose-active-item__info">
              {{ item.created_at }}
            </div>
          </div>
        </vh-scrollbar>
      </div>
      <div class="no-live" v-show="!total">
        <noData :nullType="'nullData'" :height="52" :text="text"></noData>
      </div>
    </div>
    <div slot="footer">
      <div>
        <vh-button type="info" plain @click="cancelSelect" size="medium" round>取消</vh-button>
        <vh-button
          @click="saveSelect"
          size="medium"
          v-preventReClick
          :disabled="!editId"
          type="primary"
          round
        >
          确定
        </vh-button>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import noData from './nullPage.vue';
  export default {
    data() {
      return {
        nullText: 'nullData',
        text: '暂无数据',
        total: 1,
        activeList: [],
        loading: true,
        visible: false,
        editId: null, // 传入的
        selectedItem: null
      };
    },
    components: {
      noData
    },
    inject: ['noticeApp'], // 卡片对象
    watch: {
      visible() {
        if (this.visible) {
          this.getSiteList(true);
        } else {
          this.activeList = [];
          this.activeList.map(e => {
            e.checked = false;
          });
        }
      }
    },
    methods: {
      open(config) {
        this.sourceId = config.sourceId || '';
        this.id = config.id || null;
        this.editId = config.editId || null;
        this.visible = true;
      },
      getSiteList(isClear = false) {
        this.loading = true;
        const opt = {
          webinar_id: this.noticeApp.webinarId
        };
        this.$fetch('listWebinarPages', opt, {
          'Content-Type': 'application/json'
        }).then(res => {
          if (res.code == 200) {
            if (isClear) {
              this.activeList = [];
            }
            this.activeList = this.activeList.concat(res.data.list);
            this.total = res.data.total;
            this.loading = false;
            this.syncCheckStatus();
          } else {
            this.loading = false;
          }
        });
      },
      // 同步 选中状态
      syncCheckStatus() {
        if (this.editId) {
          this.activeList.forEach(item => {
            if (item.id == this.editId) {
              this.$set(item, 'checked', true);
              this.selectedItem = item;
            } else {
              this.$set(item, 'checked', false);
            }
          });
        }
      },
      doSelect(item) {
        if (item.id == this.editId) {
          return;
        }
        this.editId = item.id;
        this.selectedItem = item;
        this.activeList.forEach(el => {
          if (item.id == el.id) {
            el.checked = true;
          } else {
            el.checked = false;
          }
        });
      },
      saveSelect() {
        this.visible = false;
        this.$emit('selected', {
          editId: this.editId,
          selectedItem: this.selectedItem
        });
      },
      cancelSelect() {
        this.visible = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  .select-matrial-wrap {
    overflow: hidden;
    padding-top: 16px;
    width: 100%;
    height: 335px;
    box-sizing: border-box;

    .material-box {
      height: 320px;
    }

    .vh-chose-active-item {
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      overflow: hidden;
      margin: 0 12px 12px 0;
      width: 168px;
      height: 150px;
      background: #f7f7f7;
      border: 1px solid #fff;
      border-radius: 4px;
      cursor: pointer;

      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }

      .img-tangle {
        position: absolute;
        top: -1px;
        right: -1px;
        z-index: 100;
        width: 20px;
        height: 20px;
        font-size: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.checkedActive {
        border: 1px solid #fb3a32;
        box-shadow: 0 4px 24px rgb(0 0 0 / 15%);
      }
      &.disabled {
        border: 1px solid #cccccc;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
        cursor: not-allowed;
        opacity: 0.5;
      }

      &__cover {
        position: relative;
        width: 100%;
        height: 93px;
        background: #1a1a1a;
        background-size: 400% 400%;
        animation: gradientBG 15s ease infinite;

        .img_box_bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: convert;
          object-position: center;

          &.box_bg_1 {
            object-fit: fill;
          }

          &.box_bg_2 {
            object-fit: cover;
            object-position: left top;
          }
        }

        &-status {
          position: absolute;
          top: 8px;
          left: 8px;
          // width: 110px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          background: rgba(0, 0, 0, 0.65);
          border-radius: 100px;
          line-height: 20px;
          font-weight: 400;

          img {
            height: 8px;
          }
        }

        &-type {
          position: absolute;
          top: 8px;
          right: 8px;
          padding: 0 8px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          background: rgba(0, 0, 0, 0.65);
          border-radius: 100px;
          box-sizing: border-box;
          line-height: 20px;
          font-weight: 400;
        }
      }

      &__title {
        display: -webkit-box;
        overflow: hidden;
        margin: 8px 8px 4px;
        font-size: 14px;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        color: #1a1a1a;
        line-height: 17px;
        font-weight: 400;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &__info {
        margin: 0 8px 8px;
        font-size: 12px;
        font-weight: 400;
        color: #666;
        line-height: 16px;
      }

      .liveTag {
        position: relative;
        z-index: 2;
        padding: 2px 8px;
        font-size: 12px;
        color: #fff;
        border-radius: 20px;

        .live-status {
          img {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  .subject_tip {
    position: absolute;
    top: 32px;
    left: 120px;
    color: #999;
  }

  ::v-deep .choose-gift {
    .head-btn.vh-input {
      width: 220px;

      .vh-icon-search {
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
<style lang="less">
  .vh-scrollbar__wrap::-webkit-scrollbar-track {
    display: none;
  }

  .select-matrial-wrap .vh-scrollbar__wrap {
    overflow-x: hidden;
  }
</style>
