<template>
  <div class="msg-notification-page">
    <MsgNotification :webinar-id="webinarId" :env="env" />
    <WechatNotification :webinar-id="webinarId"></WechatNotification>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4"
    ></begin-play>
  </div>
</template>

<script>
  import { MsgNotification } from '@vhcl/msg-notification';
  import '@vhcl/msg-notification/dist/css/index.css';
  import WechatNotification from './WeChatNotification';
  import { sessionOrLocal } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  export default {
    name: 'MsgNotificationContainer',
    components: {
      MsgNotification,
      WechatNotification,
      beginPlay
    },
    data() {
      return {
        webinarId: this.$route.params.str, // 活动id
        env: process.env.VUE_APP_ENV_HCL, // 运行时环境判断后端接口
        webinarState: JSON.parse(sessionOrLocal.get('webinarState'))
      };
    }
  };
</script>
<style scoped lang="less">
  .msg-notification-page {
    ::v-deep(.msg-notification-page) {
      .list-content {
        min-height: auto;
      }
      .msg-notification__body {
        padding-bottom: 0;
      }
    }
  }
</style>
